
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import moment from 'moment';

import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'finman-purchase',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      alertText: false,
      role_title: [] as any,
      actionCreate: false,
      createTrainer: false,
      text: '',
      file_name: '',
      cash_switch: 'no',
      bank_switch: 'no',
      tahir: false,
      product: {
        id: '',
        po_no: '',
        dob: '',
        due_date:'',
        supplier_id: '',
        supplier_ledger_id: '',
        supplier_address:'',
        supplier_name:''
      } as any,
      updateButton: false,
      supplierList: [] as any,
      professional_details: [
        {
          designation: '',
          organization: '',
          address: '',
          exp_from: '',
          exp_to: '',
        },
      ] as any,
      product_details: {
        product_id: '' as any,
        category_name: '',
        product_name: '',
        product_model_id: '',
        quantity: '' as any,
        unit_price: '' as any,
        total_price: '' as any,
        sd_percentage: '' as any,
        vat_percentage: '' as any,
        cd_percentage: '' as any,
        rd_percentage: '' as any,
        sd_amount: '' as any,
        cd_amount: '' as any,
        rd_amount: '' as any,
        vat_amount: '' as any,
        net_total: '' as any,
        sd_impose_value: '' as any,
        vat_impose_value: '' as any,
        product_category_id: '',
        inventory_ledger_id: '',
        at_ledger_id:'',
        ait_ledger_id:'',
        vat_ledger_id: '',
        category_id: '',
        g_tax_percentage: '' as any,
        g_tax_amount: '' as any,
        ait_percentage: '' as any,
        ait_amount:'' as any,
        at_percentage:'' as any,
        at_amount: '' as any,
        tax_incidence: '' as any,
        size_unit: '',
        size_measurement:'',
        weight_unit:'',
        weight_measurement:'',
        product_info:'' as any
      } as any,
      url: '',
      tranche: [],
      entityRoles: [],
      courseList: [],
      courseInfoData: false,
      entityInfos: [],
      productlistData: [] as any,
      institutes: [],
      categoryList: [] as any,
      nidData: {},
      loading: false,
      showTrainerInfo: false,
      showBankLegacy: false,
      product_details_data: [] as any,
      editItemIndex: '' as any,
      // finmanUserId: '' as any,
      finmanUserId: '' as any,
      finmantoken: '' as any,
      grand_total: '' as any,
      av_total: '' as any,
      vat_total: '' as any,
      g_tax_total: '' as any,
      ait_total: '' as any,
      at_total: '' as any,
      sd_total: '' as any,
      cd_total: '' as any,
      rd_total: '' as any,

    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      let user_id = (this.finmanUserId = VueCookieNext.getCookie('user_id'));
      this.finmanUserId = user_id;
    } else {
      this.$router.push('/404');
    }

    if (VueCookieNext.getCookie('purchase_date_'+this.finmanUserId) != null) {
      let date= VueCookieNext.getCookie('purchase_date_'+this.finmanUserId);
      this.product.dob = moment(date).format('YYYY-MM-DD');
    }
    else
    {
      this.product.dob = moment(new Date()).format('YYYY-MM-DD');
    }
    this.getSupplierList();
    this.getCategoryData();
    this.setPurchaseData();
  },
  methods: {
    saveDateCookie()
    {
      VueCookieNext.setCookie('purchase_date_'+this.finmanUserId, this.product.dob);
    },
    async setPurchaseData()
    {
      
      let invoice_id = this.$route.query.id;
      console.log("finmanInvoice/invoice/getPurchaseDetails/"+ `${invoice_id}`);
      ApiService.get("finmanInvoice/invoice/getPurchaseDetails/"+ `${invoice_id}`)
        .then((response) => {
            let invoice= response.data.data.invoice;
            this.product.id= invoice.id;
            this.product.po_no= invoice.po_no;
            this.product.date= invoice.date;
            this.product.due_date= invoice.due_date;
            this.product.supplier_id= invoice.supplier_id;
            this.product.supplier_ledger_id= invoice.supplier_ledger_id;
            this.product.supplier_name= invoice.supplier_name;
            this.product.grand_total= invoice.grand_total;
            this.product.finman_user_id= this.finmanUserId;
            this.product_details_data=response.data.data.invoice_details;
            // response.data.data.invoice_details.forEach((invoice_details) => {
            // this.product_details_data.push(JSON.parse(JSON.stringify(invoice_details)));
            // });
          this.calculateTotal();
          this.setSupplierData();
          
        }).catch(({ response }) => {

        });
    },
    setSupplierData()
    {
      let supplier= this.supplierList.find(element => element.id == this.product.supplier_id);
      console.log(supplier);
      this.product.supplier_address= supplier.address;
    },
    async getInvoiceNumber() {
      await ApiService.get(
        'finmanInvoice/getInvoicePurchaseNumber?finman_user_id=' +
          this.finmanUserId
      )
        .then((response) => {
          this.product.po_no = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    updateProduct() {
      // 1. get index convert to number then update the array on specific position with proper info
      this.product_details_data[this.editItemIndex].product_id= this.product_details.product_id;
      this.product_details_data[this.editItemIndex].quantity =
        this.product_details.quantity;

      this.product_details_data[this.editItemIndex].unit_price =
        this.product_details.unit_price;

      this.product_details_data[this.editItemIndex].sd_amount =
        this.product_details.sd_amount;

      this.product_details_data[this.editItemIndex].cd_amount =
        this.product_details.cd_amount;

      this.product_details_data[this.editItemIndex].rd_amount =
        this.product_details.rd_amount;

      this.product_details_data[this.editItemIndex].at_amount =
        this.product_details.at_amount;

      this.product_details_data[this.editItemIndex].rd_amount =
        this.product_details.ait_amount;

      this.product_details_data[this.editItemIndex].sd_percentage =
        this.product_details.sd_percentage;

      this.product_details_data[this.editItemIndex].cd_percentage =
        this.product_details.cd_percentage;

      this.product_details_data[this.editItemIndex].rd_percentage =
        this.product_details.rd_percentage;

      this.product_details_data[this.editItemIndex].vat_percentage =
        this.product_details.vat_percentage;

      this.product_details_data[this.editItemIndex].at_percentage =
        this.product_details.at_percentage;

      this.product_details_data[this.editItemIndex].ait_percentage =
        this.product_details.ait_percentage;

      this.product_details_data[this.editItemIndex].g_tax_percentage =
        this.product_details.g_tax_percentage;

      this.product_details_data[this.editItemIndex].vat_amount =
        this.product_details.vat_amount;

      this.product_details_data[this.editItemIndex].net_total =
        this.product_details.net_total;

      this.product_details_data[this.editItemIndex].g_tax_amount =
        this.product_details.g_tax_amount;

      this.product_details_data[this.editItemIndex].category_name =
        this.product_details.category_name;

      this.product_details_data[this.editItemIndex].product_name =
        this.product_details.product_name;

      let category= this.categoryList.find(element => element.id == this.product_details.product_category_id);
      this.product_details_data[this.editItemIndex].category_name = category.category_name;
      this.product_details_data[this.editItemIndex].product_category_id =
        this.product_details.product_category_id;

      let product= this.productlistData.find(element => element.id == this.product_details.product_id);
    
      this.product_details_data[this.editItemIndex].product_name = product.product_name;

      this.product_details_data[this.editItemIndex].inventory_ledger_id = product.inventory_ledger_id;
      this.product_details_data[this.editItemIndex].vat_ledger_id = product.vat_ledger_id;
      this.product_details_data[this.editItemIndex].at_ledger_id = product.at_ledger_id;
      this.product_details_data[this.editItemIndex].ait_ledger_id = product.ait_ledger_id;
      this.product_details_data[this.editItemIndex].vat_percentage = product.vat_percent;

      this.product_details_data[this.editItemIndex].product_model_id =
        this.product_details.product_model_id;

      console.log(this.product_details_data[this.editItemIndex]);

      // filed clear and make index empty again and add product visable
      this.product_details.quantity='' as any;
      this.product_details.unit_price='' as any;
      this.product_details.total_price='' as any;
      this.product_details.sd_impose_value='' as any;
      this.product_details.vat_impose_value='' as any;
      this.product_details.product_id='' as any;
      this.product_details.sd_percentage='' as any;
      this.product_details.cd_percentage='' as any;
      this.product_details.rd_percentage='' as any;
      this.product_details.at_percentage='' as any;
      this.product_details.ait_percentage='' as any;
      this.product_details.g_tax_percentage='' as any;
      this.product_details.sd_amount='' as any;
      this.product_details.cd_amount='' as any;
      this.product_details.rd_amount='' as any;
      this.product_details.at_amount='' as any;
      this.product_details.ait_amount='' as any;
      this.product_details.vat_amount='' as any;
      this.product_details.g_tax_amount='' as any;
      this.product_details.net_total='' as any;
      this.product_details.category_name='' as any;
      this.product_details.product_name='' as any;
      this.product_details.product_category_id='' as any;
      this.product_details.product_model_id='' as any;
      this.product_details.tax_incidence='' as any;

      this.calculateTotal();

      
      this.editItemIndex = '' as any;
      this.updateButton = false;
    },
    editProduct(data, index) {
      // set the data first
      this.editItemIndex = index;
      this.product_details.product_category_id = data.product_category_id;
      this.productlist(data.product_category_id);
      this.product_details.product_id= data.product_id;
      this.product_details.product_model_id = data.product_model_id;
      this.product_details.quantity = Number(data.quantity);
      this.product_details.unit_price = Number(data.unit_price);
      this.product_details.sd_percentage = Number(data.sd_percentage);
      this.product_details.cd_percentage = Number(data.cd_percentage);
      this.product_details.rd_percentage = Number(data.rd_percentage);
      this.product_details.vat_percentage = Number(data.vat_percentage);
      this.product_details.at_percentage = Number(data.at_percentage);
      this.product_details.ait_percentage = Number(data.ait_percentage);
      this.product_details.g_tax_percentage = Number(data.g_tax_percentage);
      this.product_details.sd_amount = Number(data.sd_amount);
      this.product_details.cd_amount = Number(data.cd_amount);
      this.product_details.rd_amount = Number(data.rd_amount);
      this.product_details.vat_amount = Number(data.vat_amount);
      this.product_details.at_amount = Number(data.at_amount);
      this.product_details.ait_amount = Number(data.ait_amount);
      this.product_details.net_total = Number(data.net_total);
      this.product_details.g_tax_amount = Number(data.g_tax_amount);
      this.calculateData();
      // enable edit bTn
      this.updateButton = true;
    },
    async calculateData() {

      if(Number(this.product_details.unit_price)>0)
      {
      //   this.product_details.unit_price= this.product_details.total_price/this.product_details.quantity;
      // }
      // else
      // {
        this.product_details.total_price= Number(this.product_details.quantity * this.product_details.unit_price);
      }

      this.product_details.cd_amount =
        Number(
          this.product_details.cd_percentage * this.product_details.total_price
        ) / 100;
      this.product_details.rd_amount =
        Number(
          this.product_details.rd_percentage * this.product_details.total_price
        ) / 100;

      let sd_impose_value= Number(this.product_details.cd_amount)
                           + Number(this.product_details.rd_amount) 
                           + Number(this.product_details.total_price);

      this.product_details.sd_impose_value= sd_impose_value;


      // update sd amount and vat amount
      this.product_details.sd_amount =
        (Number(
          this.product_details.sd_percentage) * Number(sd_impose_value)
        ) / 100;

      let vat_impose_value= Number(this.product_details.total_price) +  
                            Number(this.product_details.cd_amount) + 
                            Number(this.product_details.rd_amount) + 
                            Number(this.product_details.sd_amount);

      this.product_details.vat_impose_value= vat_impose_value;
     
      this.product_details.vat_amount =
        Number(
          this.product_details.vat_percentage * 
          (vat_impose_value)) / 100;

      this.product_details.at_amount =
        (Number(
          this.product_details.at_percentage) * Number(vat_impose_value)
        ) / 100;

      this.product_details.ait_amount =
        (Number(
          this.product_details.ait_percentage) * Number(this.product_details.total_price)
        ) / 100;
        
        // calculate net total
      this.product_details.total_price =
        (Number(this.product_details.quantity) *
        Number(this.product_details.unit_price));


      this.product_details.g_tax_amount = (Number(this.product_details.g_tax_percentage)* Number(this.product_details.total_price))/100;



      this.product_details.tax_incidence =
          Number(this.product_details.sd_amount) +
          Number(this.product_details.cd_amount) +
          Number(this.product_details.rd_amount) +
          Number(this.product_details.vat_amount)+
          Number(this.product_details.at_amount)+
          Number(this.product_details.ait_amount)
          ;


      this.product_details.net_total =
          Number(this.product_details.total_price) +
          Number(this.product_details.sd_amount) +
          Number(this.product_details.cd_amount) +
          Number(this.product_details.rd_amount) +
          Number(this.product_details.g_tax_amount);

      
          // calculateGTaxData();

      
    },
    // async calculateGTaxData(){
    //   this.product_details.net_total =
    //     (Number(this.product_details.quantity) *
    //       Number(this.product_details.unit_price)) +
    //       Number(this.product_details.sd_amount) +
    //       Number(this.product_details.cd_amount) +
    //       Number(this.product_details.rd_amount) +
    //       Number(this.product_details.vat_amount) +
    //       Number(this.product_details.g_tax_amount) +
    //       Number(this.product_details.at_amount) +
    //       Number(this.product_details.ait_amount)
    // },
    async getCategoryData() {
      await ApiService.get('finmanInvoice/category/list?type=2')
        .then((response) => {
          this.categoryList = response.data.data;

          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getSupplierList() {
      await ApiService.get('finmanInvoice/supplier/list')
        .then((response) => {
          this.supplierList = response.data.data;

          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async productlist(category_id) {
      await ApiService.get(
        'finmanInvoice/product/listForInvoice?category_id=' + category_id
      )
        .then((response) => {
          this.productlistData = response.data.data;

          // for (let i = 0; i < response.data.data.length; i++) {
          //   this.productlistData.push({
          //     id: response.data.data[i].id,
          //     name: `${response.data.data[i].product_name}-${response.data.data[i].brand}-${response.data.data[i].unit_price}`,
          //   });
          // }
          // this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getProductInfo() {
      let product_info_data= this.productlistData.find(element => element.id == this.product_details.product_id);
      this.product_details.product_name = '';
      this.product_details.sd_amount = '';
      this.product_details.cd_amount = '';
      this.product_details.rd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.at_amount = '';
      this.product_details.ait_amount = '';
      this.product_details.total_price= '';
      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.cd_percentage = '';
      this.product_details.rd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.at_percentage = '';
      this.product_details.ait_percentage = '';
      this.product_details.g_tax_percentage = '';
      this.product_details.g_tax_amount = '';
      this.product_details.sd_impose_value = '';
      this.product_details.vat_impose_value = '';
      this.product_details.net_total = '';

      this.product_details.product_name= product_info_data.product_name;
      this.product_details.category_name= product_info_data.category_name;
      this.product_details.product_color= product_info_data.color;
      this.product_details.size_measurement = product_info_data.size_measurement;
      this.product_details.weight_measurement = product_info_data.weight_measurement;
      this.product_details.size_unit = product_info_data.size_unit;
      this.product_details.weight_unit = product_info_data.weight_unit;
      this.product_details.unit_price = product_info_data.unit_price;
      this.product_details.sd_percentage = product_info_data.sd_percent;
      this.product_details.cd_percentage = product_info_data.cd_percent;
      this.product_details.rd_percentage = product_info_data.rd_percent;
      this.product_details.at_percentage = product_info_data.at_percent;
      this.product_details.ait_percentage = product_info_data.ait_percent;
      this.product_details.g_tax_percentage = product_info_data.g_tax_percent;
      this.product_details.inventory_ledger_id = product_info_data.inventory_ledger_id;
      this.product_details.vat_ledger_id = product_info_data.vat_ledger_id;
      this.product_details.at_ledger_id = product_info_data.at_ledger_id;
      this.product_details.ait_ledger_id = product_info_data.ait_ledger_id;
      this.product_details.vat_percentage = product_info_data.vat_percent;
      this.product_details.sd_amount = (product_info_data.sd_percent * product_info_data.unit_price) / 100;
      this.product_details.cd_amount = (product_info_data.cd_percent * product_info_data.unit_price) / 100;
      this.product_details.rd_amount = (product_info_data.rd_percent * product_info_data.unit_price) / 100;
      this.product_details.vat_amount = (product_info_data.vat_percent 
      * (product_info_data.unit_price + this.product_details.sd_amount + this.product_details.cd_amount + this.product_details.rd_amount)
      ) / 100;
      this.product_details.g_tax_amount =product_info_data.g_tax_amount;
      this.product_details.net_total= this.product_details.unit_price + this.product_details
      this.calculateData();

    },
    addProduct() {
      console.log(this.product_details);
      this.product_details_data.push(
        JSON.parse(JSON.stringify(this.product_details))
      );
      this.product_details.quantity = '';
      this.product_details.product_category_id = '';
      this.product_details.product_id = '';
      this.product_details.product_model_id = '';
      this.productlistData = [] as any;
      this.product_details.unit_price = '';
      this.product_details.total_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.cd_percentage = '';
      this.product_details.rd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.sd_amount = '';
      this.product_details.cd_amount = '';
      this.product_details.rd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.net_total = '';
      this.product_details.g_tax_amount = '';
      this.product_details.ait_percentage = '';
      this.product_details.ait_amount = '';
      this.product_details.at_percentage = '';
      this.product_details.at_amount = '';
      this.calculateTotal();
    },

    calculateTotal()
    {
      const sum = this.product_details_data.reduce(
            (acc, cur) => {
              acc.vat_total += cur.vat_amount;
              acc.grand_total += cur.net_total;
              acc.av_total += (cur.quantity* cur.unit_price);
              acc.g_tax_total += cur.g_tax_amount;
              acc.sd_total += cur.sd_amount;
              acc.cd_total += cur.cd_amount;
              acc.rd_total += cur.rd_amount;
              acc.ait_total += cur.ait_amount;
              acc.at_total += cur.at_amount;
              return acc;
            },
            {
              vat_total: 0,
              grand_total: 0,
              av_total: 0,
              g_tax_total: 0,
              sd_total: 0,
              cd_total: 0,
              rd_total: 0,
              ait_total:0,
              at_total:0
            }
          );
      this.grand_total= sum.grand_total;
      this.vat_total= sum.vat_total;
      this.av_total= sum.av_total;
      this.g_tax_total= sum.g_tax_total;
      this.sd_total= sum.sd_total;
      this.cd_total= sum.cd_total;
      this.rd_total= sum.rd_total;
      this.ait_total= sum.ait_total;
      this.at_total= sum.at_total;
    },
    removeProduct(data) {
      const productitemIndex = this.product_details_data.indexOf(data);
      if (productitemIndex > -1) {
        // only splice array when item is found
        this.product_details_data.splice(productitemIndex, 1); // 2nd parameter means remove one item only
      }
      // filed clear and make index empty again and add product visable
      this.product_details.product_category_id = '';
      this.product_details.product_model_id = '';
      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.cd_percentage = '';
      this.product_details.rd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.sd_amount = '';
      this.product_details.cd_amount = '';
      this.product_details.rd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.g_tax_amount = '';
      this.product_details.at_percentage = '';
      this.product_details.at_amount = '';
      this.product_details.ait_percentage = '';
      this.product_details.ait_amount = '';
      this.product_details.quantity = '';
      this.product_details.net_total = '';
      this.editItemIndex = '' as any;
      this.updateButton = false;

      this.calculateTotal();
    },

    bank_change(e) {
      if (e.target.checked) {
        this.product.bank = '1';
        this.showBankLegacy = true;
        this.product.transaction_id = this.product.transaction_id;
        this.product.cash = '0';
        this.cash_switch = 'no';
      } else {
        this.product.bank = '0';
        this.showBankLegacy = false;
      }
    },
    cashChange(e) {
      if (e.target.checked) {
        this.product.cash = '1';
        this.product.bank = '0';
        this.product.transaction_id = '' as any;
        this.showBankLegacy = false;
        this.bank_switch = 'no';
      } else {
        this.product.cash = '0';
      }
    },

    async formSubmit() {
      // console.log(this.product);
      // console.log(this.product_details_data);
      // return 1;
      let formData = new FormData();

      for (var key in this.product) {
        formData.set(key, this.product[key]);
      }
      formData.set(
        'product_details',
        JSON.stringify(this.product_details_data)
      );
      formData.set('finmanUserId', this.finmanUserId);
      this.loading = true;

      const grandtotal = this.product_details_data.reduce(
        (accumulator, object) => {
          return accumulator + object.net_total;
        },
        0
      );
      formData.set('grand_total', grandtotal);

      await ApiService.post('finmanInvoice/purchaseEdit', formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.product = {
                po_no: '',
                dob: '',
                po: '',
                supplier_id: '',
              };
              this.product_details_data = [] as any;
              this.$router.push('/purchase-invoices');
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
